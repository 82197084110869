<template>
  <div class="index">
    <div class="content">
      <div class="about">
        <!-- <div class="about-left">
          <img
            src="../../assets/image/about.png"
            alt=""
          >
        </div> -->
        <div class="about-right">
          <div class="text">
            ❶成本低：根据企业量身定制报价，保函费用低，不占用企业资金，合作多家银行机构额度充足，开函效率高。
          </div>
          <div class="text">
            ❷全程电子化：与全国各地公共资源交易中心展开电子保函合作，提供保函申请、开具、
            赔付等全过程电子化服务体系。
          </div>
          <div class="text">
            ❸高效、简单：根据项目情况，流程标准化管理，审批速度快。
          </div>
          <div class="text">
            ❹信用可靠：持牌机构，依托集团及合作银行诚信经营，保函真实有效，风险管控能力强。
          </div>
          <!-- <a-button
            type="link"
            @click="$router.push('./brandintroduction')"
          >
            查看更多
          </a-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.index{
    display: flex;
    flex-direction: column;
    height: 780px;
    background-color: #f2f2f2;
}
.content {
  width: 1200px;
  height: 700px;
  margin: 0 auto;
  background: #ffff;
  margin-top: 50px;
}
.about {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    .about-left {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
      }
    }
    .about-right {
      position: relative;
      .text {
        text-align:justify;
        // margin-left: 100px;
        // width: 670px;
        text-indent: 2em;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666;
        line-height: 100px;
      }
      ::v-deep .ant-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 96px;
        height: 40px;
        border: 1px solid #ddd;
        border-radius: 20px;
        font-size: 0.875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999;
        background-color: #fff;
        cursor: pointer;
        transition: all 0.5s;
      }
    }
  }
</style>
